/* eslint-disable no-trailing-spaces */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import {
    DTO, DTORef, DTOMetaData, DTOMethods, DTOClassMethods,
    BoundEndpointAPI, Attachment
} from '../dto';
import { Persona, Personas } from './persona';

export interface User_v1 extends DTO, DTOMethods<User_v1> {
    email: string;
    // contactTitle: string;
    title: string;
    status: string;     // 'Approved', 'Revoked/blocked'. TODO: How to revoke access?
    isActive: boolean;    // Means: Is this a 'residual' user?
    invited: boolean;   // Means: Has user been given permission to log in?
    lastActive: Date;   // Date of last active.
    prefix: string;
    suffix: string;
    firstName: string;
    lastName: string;
    middleInitial: string;
    nickname: string;
    photo: string;
    persona?: string;
    personaCode?: Persona;
    personaDescription?: string;
    organization: DTORef;
    groups: DTORef[];
    preferences: Attachment[];
    filters: Attachment[];
    favorites: Attachment[];
    layouts: Attachment[];
    subscriptions: Attachment[];
}
export const User_v1: DTOMetaData & DTOClassMethods<User_v1> = {
    _label:'Users',
    _dto: 'User_v1',
    _type: 'user',
    _colors: { primaryThemeColor: "#c2ac19", secondaryThemeColor: "#c2ac19", tertiaryThemeColor: "#c2ac19" },
    _keysets: ["id"], // TODO: Generate from DTO Schema
    _properties: [
        { label: 'Email', prop: 'email', hint: 'Text (max 64k chars)', type: 'string', fixed: false, source: undefined, values: undefined, readonly: true },
        { label: 'Title', prop: 'title', hint: 'Text (max 64k chars)', type: 'string', fixed: false, source: undefined, values: undefined },
        { label: 'Is Active', prop: 'isActive', hint: 'IsActive', type: 'boolean', fixed: false, source: undefined, values: undefined },
        { label: 'Last Active', prop: 'lastActive', hint: 'Date and time', type: 'Date', fixed: true, source: undefined, values: undefined },
        // { label: 'Prefix', prop: 'prefix', hint: 'Text (max 64k chars)', type: 'string', fixed: false, source: undefined, values: undefined },
        // { label: 'Suffix', prop: 'suffix', hint: 'Text (max 64k chars)', type: 'string', fixed: false, source: undefined, values: undefined },
        { label: 'First Name', prop: 'firstName', hint: 'Text (max 64k chars)', type: 'string', fixed: false, source: undefined, values: undefined },
        { label: 'Last Name', prop: 'lastName', hint: 'Text (max 64k chars)', type: 'string', fixed: false, source: undefined, values: undefined },
        // { label: 'Middle initial', prop: 'middleInitial', hint: 'Text (max 64k chars)', type: 'string', fixed: false, source: undefined, values: undefined },
        // { label: 'Nickname', prop: 'nickname', hint: 'Text (max 64k chars)', type: 'string', fixed: false, source: undefined, values: undefined },
        // { label: 'Photo', prop: 'photo', hint: 'Text (max 64k chars)', type: 'string', fixed: false, source: undefined, values: undefined },
        { label: 'Persona', prop: 'persona', hint: 'Persona', type: 'enum', fixed: false, source: undefined, values: Personas },
        { label: 'Organization', prop: 'organization', hint: 'Organization', type: 'dto', fixed: false, source: '/api/eom/v1.0/organization/v1.0', values: undefined },
    ],
    _related: [
        { prop: 'preferences', type: 'unknown', class: 'Unknown', label: 'Preferences' },
        { prop: 'assignments', type: 'unknown', class: 'Unknown', label: 'Assignments' },
        { prop: 'filters', type: 'unknown', class: 'Unknown', label: 'Filters' },
        { prop: 'favorites', type: 'unknown', class: 'Unknown', label: 'Favorites' },
        { prop: 'layouts', type: 'unknown', class: 'Unknown', label: 'Layouts' },
        { prop: 'subscriptions', type: 'unknown', class: 'Unknown', label: 'Subscriptions' },
        { prop: 'groups', type: 'group', class: 'Group_v1', label: 'Groups' },
     ],
    _endpoint: '/api/eom/v1.0/user/v1.0',
    _docLink: '/wiki/elevate/Assets/user/',
    _parentDTONames: 'Organization_v1',
    _icon: './assets/dtos/user.svg',

    endpointAPI: new BoundEndpointAPI<User_v1>('User_v1', DTO),
    from: (obj: any): User_v1 => {
        return Object.setPrototypeOf(obj, User_v1._prototype);
    },
    select: (p: any = ''): any => {
        return User_v1.endpointAPI.get(p).then(res =>
            !res ? undefined
            : Array.isArray(res) ? res.map(a => User_v1.from(a) as User_v1)
            : User_v1.from(res)
    )}
}
